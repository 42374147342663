import React from "react";

import { Navbar } from "../../components";
import { Footer } from "../../containers";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <div
        id="terms"
        className="px-8 pb-20 md:px-20 mt-10 text-renownBlack leading-relaxed"
      >
        <h1 className="text-2xl md:text-3xl font-semibold">Privacy Policy</h1>
        <p className="mt-4 text-sm md:text-base">
          At Brandkart, we recognize that privacy is important. This policy
          applies to the services mentioned in the Terms of Use. We will always
          place customers before money. We understand that the information that
          you share with us is extremely personal, and we go the extra mile to
          maintain its privacy. This Policy is published and maintained by Ping
          Pong KGP Techno Private Limited (Brandkart). This Policy applies to
          the website of Brandkart, https://brandkart.co. We may change this
          policy from time to time by updating this page. You should check this
          page from time to time to review the then-current policy because they
          are binding on you. By accessing this Site, you give an implied
          consent and agree to the terms of this policy and that information
          furnished by you is lawful, true and correct and does not violate or
          infringe any laws. In case of any violations, infringement, furnishing
          of wrongful or unauthorised information, Brandkart shall not be liable
          for the same.
        </p>
        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Information we collect
        </h2>
        <p className="mt-2 text-sm md:text-base">
          We may collect personal information that can identify you such as your
          name and email address, your gender, age, your pin code, demographic
          information and such other information as may be required. When you
          provide personal information through our website, the information may
          be sent to servers located in India and other countries around the
          world.
        </p>
        <p className="mt-2 text-sm md:text-base">
          We may also collect and store personal information about other people
          that you provide to us. If you use our website to send others
          (friends, relatives, colleagues, etc.) information that may interest
          them through our system, we may store your personal information, and
          the personal information of each such recipient. Brandkart will not
          share the personal information you provide to us of your friends and
          family members with anyone, unless required by law, court order or to
          technically operate our website or service.
        </p>

        <p className="mt-2 text-sm md:text-base">
          We automatically collect information from your browser when you visit
          our website. This information includes your IP address, your browser
          type and language, access times, the content of any undeleted cookies
          that your browser previously accepted from us (see "Cookies" below),
          and the referring website address.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Children and Minors
        </h2>

        <p className="mt-2 text-sm md:text-base">
          We do not knowingly collect Personal Information from anyone under the
          age of majority in the jurisdiction in which they reside. If we become
          aware that we have unknowingly collected personally identifiable
          information from an individual under the age of majority in the
          jurisdiction in which they reside, we will delete this information
          from our records or, if that is not reasonable, we will take
          reasonable available steps to de-identify the information.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Use of Information
        </h2>

        <p className="mt-2 text-sm md:text-base">
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
          <ul className="mt-2 list-disc ml-6">
            <li>Provide the services that you have requested;</li>
            <li>Internal record keeping.</li>
            <li>
              Periodically send promotional emails about special offers or other
              information which we think you may find interesting.
            </li>
            <li>
              From time to time, we may also use your information to contact you
              for market research purposes. We may contact you by email, phone,
              fax or mail. We may use the information to customise the website
              according to your interests.
            </li>
            <li>Manage your account and provide you with requested support;</li>
            <li>
              Develop and display content tailored to your interests on our
              site;
            </li>
            <li>Enforce our terms and conditions;</li>
            <li>Manage our business and</li>
            <li>
              Perform functions as otherwise described to you at the time of
              collection.
            </li>
            <li>
              We do not share your personal information with others except as
              indicated below or when we inform you and give you an opportunity
              to opt out of having your personal information shared.
            </li>
          </ul>
          <p className="mt-2">We also may disclose your information:</p>
          <ul className="mt-2 list-disc ml-6">
            <li>
              In response to a court order, or a request for cooperation from
              law enforcement or other government agency; to establish or
              exercise our legal rights; to defend against legal claims; or as
              otherwise required by law. In such cases, we may raise or waive
              any legal objection or right available to us.
            </li>
            <li>Internal record keeping.</li>
            <li>
              When we believe disclosure is appropriate in connection with
              efforts to investigate, prevent, or take other action regarding
              illegal activity, suspected fraud or other wrongdoing; to protect
              and defend the rights, property or safety of our company, our
              users, our employees, or others; to comply with applicable law or
              cooperate with law enforcement; or to enforce our website terms
              and conditions or other agreements or policies.
            </li>
            <li>
              In connection with a substantial corporate transaction, such as
              the sale of our business, a divestiture, merger, consolidation, or
              asset sale, or in the unlikely event of bankruptcy.
            </li>
          </ul>
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Security</h2>

        <p className="mt-2 text-sm md:text-base">
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Cookies</h2>

        <p className="mt-2 text-sm md:text-base">
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyse web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences. We use traffic log cookies to identify which
          pages are being used. This helps us analyse data about webpage traffic
          and improve our website in order to tailor it to user needs. We only
          use this information for statistical analysis purposes and then the
          data is removed from the system.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Links to other websites
        </h2>
        <p className="mt-2 text-sm md:text-base">
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this privacy policy. You should exercise
          caution and look at the privacy policy applicable to the website in
          question.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Controlling your personal information
        </h2>

        <p className="mt-2 text-sm md:text-base">
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>
        <p className="mt-2 text-sm md:text-base">
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at
          support@oneimpression.io. We will promptly correct any information
          found to be incorrect.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Retention of Data
        </h2>
        <p className="mt-2 text-sm md:text-base">
          We store and retain data for as long as we need it (amongst other, we
          consider the amount, nature, and sensitivity of the Personal Data, the
          potential risk of harm from unauthorized use or disclosure of your
          Personal Data, the purposes for which we process your Personal Data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements).
        </p>
        <p className="mt-2 text-sm md:text-base">
          In some circumstances we retain your Personal Data as follows: (i)
          where we are required to do so in accordance with legal, regulatory,
          tax or accounting requirements, (ii) for us to have an accurate record
          of your dealings with us in the event of any complaints or challenges,
          or (iii) if we reasonably believe there is a prospect of litigation
          relating to your Personal Data or dealings.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Visiting our websites from outside India
        </h2>
        <p className="mt-2 text-sm md:text-base">
          This Privacy Policy is intended to cover collection of information on
          our website from persons who are ordinarily resident in India. If you
          are visiting our website from outside India, please be aware that your
          information may be transferred to, stored, and processed in India
          where our servers are located and our central database is operated.
          The data protection and other laws of India and other countries might
          not be as comprehensive as those in your country. Please be assured
          that we seek to take reasonable steps to ensure that your privacy is
          protected. By using our services, you understand that your information
          may be transferred to our facilities and those third parties with whom
          we share it as described in this privacy policy.
        </p>
        <p className="mt-2 text-sm md:text-base">
          By accessing this Site, certain information about you, such as
          Internet protocol (IP) addresses, navigation through the Site, the
          systems and programs used and the time spent, along with other similar
          information, may be stored on our servers.
        </p>
        <p className="mt-2 text-sm md:text-base">
          Such stored information may not specifically identify you. Please be
          informed that the same may be used by us for web site traffic analysis
          or for such other purposes as we may deem appropriate.
        </p>
        <p className="mt-2 text-sm md:text-base">
          If you provide unique identifying information, such as name, address
          and other information on forms stored on this Site, such information
          may be used for statistical and/or other purposes and may or may not
          be published for access by other entities with whom we have a
          commercial relationship. We assume no responsibility for the security
          of this information and shall not be held liable or responsible to
          bear or pay any monies to any person whatsoever from our usage of the
          information made available to us on this Site.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Contact Us</h2>
        <p className="mt-2 text-sm md:text-base">
          This document shall be read and interpreted in accordance with Indian
          law, without regard to conflict of law provisions subject to the
          exclusive jurisdiction of the appropriate courts located in Bengaluru,
          Karnataka, India.
        </p>
        <p className="mt-2 text-sm md:text-base">
          This Privacy Policy may be updated periodically to reflect changes to
          our personal information practices. We strongly encourage you to refer
          to the Privacy Policy often for the latest information about our
          personal information practices.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
