import React, { useState } from "react";

import "./getverified.css";

import { images } from "../../constants";

// import { Button, LinearProgress, MenuItem } from "@mui/material";

import { Formik } from "formik";

const GetVerified = () => {
  const [email, setEmail] = useState("");

  return (
    <div className="get-verified px-8 lg:px-20 h-min py-24">
      <div className="get-verified-box py-14 md:py-20 flex flex-col lg:flex-row lg:items-center lg:justify-between lg:pr-16">
        <div className="px-10 flex-1 lg:px-20 xl:relative xl:bottom-4 text-white">
          <h2 className="text-3xl text-left lg:text-4xl leading-tight font-medium">
            Join Brandkart Club
          </h2>
          <p className="mt-4 md:mt-2 text-base ss:text-lg w-full lg:w-10/12">
            Subscribe to our newsletter to stay updated on latest branding
            initiatives and trends.
          </p>
        </div>

        <div className="mt-10 lg:mt-0 flex-1 flex flex-col justify-center items-center py-2">
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                alert("subscription successful");
                setSubmitting(false);
              }, 400);
              fetch("https://api.sheetmonkey.io/form/rjGkPBqGZjqXs9uw6GBPxt", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((response) =>
                  console.log("Success:", JSON.stringify(response))
                )
                .catch((error) => console.error("Error:", error));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <div className="w-full flex justify-center">
                <div className="lg:flex w-10/12 lg:w-full py-2 lg:py-2 bg-white rounded-full">
                  <form onSubmit={handleSubmit} className="w-full">
                    <div className="flex items-center justify-between pr-2">
                      <input
                        className="w-8/12 pl-5 md:pl-6 text-sm md:text-base rounded-full outline-0"
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {/* {errors.email && touched.email && errors.email} */}
                      <button
                        className="lg:flex hover:bg-violet-700 bg-purple active:bg-green-600 rounded-full py-2 px-4 md:px-6 text-white text-sm md:text-base"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <img id="blue-dots" src={images.dots_blue} width={160} />
    </div>
  );
};

export default GetVerified;
