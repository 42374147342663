import React from "react";
import "./header.css";

import { images } from "../../constants";

import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="flex flex-col lg:flex-row px-8 lg:px-20 h-min pb-0 lg:pb-20">
      <div className="flex-1">
        <h1 className="mt-10 lg:mt-20 text-bkBlack2 text-center leading-snug lg:leading-hero-title lg:text-left lg:pr-32 text-4xl md:text-5xl lg:text-headerTitle font-bold">
          Brand Requirements Simplified
        </h1>
        <p className="mt-6 text-renownBlack text-base md:text-lg text-center lg:text-left lg:leading-hero-desc md:px-24 lg:pl-0 lg:pr-44">
          All branding requirements at one place. Connect with Influencers, Sign
          brand ambassadors, Partner with movies, shows, events.
        </p>
        <div className="flex justify-center lg:justify-start">
          <Link to="/get-started">
            <button
              className="text-xs mt-7 font-medium md:text-base border-solid border-2 border-purple bg-purple text-white px-3 lg:px-4 py-2 rounded-md"
              type="button"
            >
              Get Started
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-10 lg:mt-16 flex-1 flex relative sm:static right-3 justify-center">
        <img
          className="object-contain"
          src={images.hero_image}
          width={550}
          alt="hero-image"
        />
      </div>
    </div>
  );
};

export default Header;
