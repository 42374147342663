import React from "react";
import "./App.css";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home, GetStarted } from "./pages";

import { StartNow } from "./pages";
import { JoinNow } from "./pages";

import { Terms } from "./pages";
import { PrivacyPolicy } from "./pages";

const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/get-started" element={<GetStarted />} />
            <Route path="/get-started/start-now" element={<StartNow />} />
            <Route path="/get-started/join-now" element={<JoinNow />} />
            <Route path="/terms&conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </div>
  );
};

export default App;
