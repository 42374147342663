import React from "react";
import "./brands.css";

import { logos, images } from "../../constants";

const Brands = () => {
  return (
    <div className="brands h-min py-10 lg:pt-0">
      <div className="flex justify-center">
        <h3 className="text-purple text-xl font-medium uppercase">Brands</h3>
      </div>
      <div className="flex justify-center">
        <p className="mt-5 text-subhead font-semibold text-center text-2xl ss:text-3xl md:text-4xl xl:text-secHeading xl:leading-secHeading">
          Brands who love our work
        </p>
      </div>
      <div className="line-3 px-10">
        <div className="w-20 sm:w-28 md:w-32 lg:w-28">
          <img src={logos.haier} alt="haier" />
        </div>
        <div className="w-24 sm:w-28 md:w-32 lg:w-36">
          <img src={logos.hoichoi} alt="hoichoi" />
        </div>
        <div className="w-24 sm:w-28 md:w-32 lg:w-36">
          <img src={logos.nestle} alt="nestle" />
        </div>
        <div className="w-24 sm:w-28 md:w-32 lg:w-36">
          <img src={logos.milaap} alt="milaap" />
        </div>
        <div className="w-24 sm:w-28 md:w-32 lg:w-24 relative top-2">
          <img src={logos.grb} alt="grb" />
        </div>
        <div className="w-24 sm:w-28 md:w-32 lg:w-36">
          <img src={logos.myprotein} alt="myprotein" />
        </div>
      </div>
      <div className="flex justify-center mt-10 lg:mt-20">
        <div
          id="help-you-box"
          className="w-11/12 py-6 lg:bg-white
        lg:px-14 xl:py-12
        flex flex-col lg:flex-row lg:rounded-full"
        >
          <div className="flex justify-center items-center flex-1">
            <p className="w-4/5 lg:w-full text-3xl md:text-4xl xl:text-brandsDesc xl:leading-brands-desc font-semibold text-center lg:text-left pl-0 lg:pl-10 xl:px-12">
              We are here to help you build your brand
            </p>
          </div>
          <div className="flex-1 flex flex-col justify-center xl:items-center">
            <h3 className="text-xl md:text-3xl mt-8 xl:mt-0 text-center xl:text-left">
              <span>100k+ </span> Active Users
            </h3>
            <div className="mt-4 lg:mt-0 flex justify-center xl:justify-start xl:relative xl:right-6">
              <img src={images.auimages} width={225} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
