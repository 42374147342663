import React from "react";

import { Navbar } from "../../components";
import { Footer } from "../../containers";
import "./terms.css";

const Terms = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <div
        id="terms"
        className="px-8 pb-20 md:px-20 mt-10 text-renownBlack leading-relaxed"
      >
        <h1 className="text-2xl md:text-3xl font-semibold">
          Terms and Conditions
        </h1>
        <p className="mt-4 text-sm md:text-base">
          This is an Agreement between you and Ping Pong KGP Techno Private
          Limited, an Indian Company with registered office at 4th Floor, Aditya
          Complex, Kothnur Main Road, Gaurav Nagar, JP Nagar 7th Phase, J. P.
          Nagar, Bengaluru, Karnataka IN - 560078 (hereinafter – “Brandkart”)
          that describes the Terms of Service (“Terms”) with respect to your
          access to and use of content, reports, documents, products, and
          services (referred to collectively as the “Services”) we make
          available through https://brandkart.co (referred to collectively as
          the “Site”). Please take the time to read the Agreement carefully as
          it governs your use of the Site and Services.
        </p>
        <p className="mt-2 text-sm md:text-base">
          Here at BRANDKART we believe that all influencer posts should be
          transparent and put consumers first. Therefore, both Influencers and
          Brands need to ensure that no campaign or post is false or misleading
          and must disclose the fact that the Influencer is being paid by the
          Brand. BRANDKART expects that all users comply with all applicable
          Laws and industry self-regulation relating to influencer marketing,
          including the Advertising Standard Council of India (ASCI), AANA
          Guidelines (AU), CAP Code (UK), and Federal Trade Commission’s Guides
          Concerning Endorsements and Testimonials in Advertising (USA) as
          applicable to each Brand. This means that all influencer marketing
          posts which are being facilitated through BRANDKART should be
          transparent and labelled with appropriate disclosures, such as #ad. We
          expect Influencers and Brands to keep themselves up to date with the
          latest best practice on how to label influencer marketing posts in a
          transparent way in order to make sure that consumers are clear about
          the commercial nature of the sponsored posts.
        </p>
        <p className="mt-2 text-sm md:text-base">
          These Customer Terms of Service (the “Agreement”) shall outline the
          mutual understanding between the Parties. The Parties hereto agree as
          follows:
        </p>
        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Introduction</h2>
        <p className="mt-2 text-sm md:text-base">
          These terms and conditions govern your use of the website
          https://brandkart.co (hereinafter referred as ‘website’). Ping Pong
          KGP Techno Private Limited (hereinafter referred to as ‘company’) is
          the sole and exclusive owner of the Brandkart, owned and registered
          solely and exclusively in its name. By using Brandkart in any manner
          whatsoever and in any capacity whatsoever, you accept these terms and
          conditions in full.
        </p>
        <p className="mt-2 text-sm md:text-base">
          This document is an electronic record in terms of the Information
          Technology Act, 2000 ("IT Act") and the rules thereunder as applicable
          and the provisions pertaining to electronic records in various
          statutes as amended by the IT Act and is published in accordance with
          the provisions of applicable laws, including the Consumer Protection
          (E-Commerce) Rules 2020, that require publishing the rules and
          regulations, privacy policy and terms of use for access or usage of
          the Platform. Further, platform for the purposes of this documents
          means the Brandkart. This electronic record is generated by a computer
          system and does not require any physical or digital signatures.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#User Account</h2>

        <p className="mt-2 text-sm md:text-base">
          You will be required to successfully sign up for an account (the “User
          Account”) and be issued with a username and password login (“User ID”)
          in order to use the Services. If you are issued with a User ID, you
          shall keep your User ID secure and shall not share your User ID with
          anyone else, and you shall not collect or harvest any personal data of
          any other user of Brandkart, including account names. Brandkart
          reserves the right to disable any Personal Account issued to you at
          any time at Brandkart’s sole discretion. If Brandkart disables access
          to a Personal Account issued to you, you may be prevented from
          accessing Brandkart, your account details or any Campaigns that are
          associated with your account.
        </p>
        <p className="mt-2 text-sm md:text-base">
          By registering with Brandkart you agree to receive emails, including
          but not limited to: newsletters, product updates, opportunities. You
          will be able to unsubscribe from each group. By accessing or using the
          Website, You represent and warrant that You will be legally binding
          contracts with Us. If You act on behalf of a business entity in
          accepting these Terms of Service, You warrant and undertake that You
          have the requisite power and authority to act on behalf of that entity
          and bind the entity to these Terms of Service. By registering the User
          Account, You agree to: (i) provide and keep current, accurate and
          complete information about Yourself and Your company (“Information”);
          (ii) You agree to accept responsibility for all activities that occur
          under the User Account. If We have reasonable grounds to suspect that
          the Information You provide is inaccurate, incomplete or impersonates
          another person, We reserve the right to suspend or terminate your User
          Account. All Information will be stored and used in accordance with
          our Privacy Policy.
        </p>
        <p className="mt-2 text-sm md:text-base">
          In order to register the User Account, you will be asked to choose a
          sign-in name (“Sign-In Name”), a password (“Password”), and perhaps
          certain additional information that will assist in authenticating your
          identity when you log-in in the future (“Unique Identifiers”). When
          creating Your Account, You must provide true, accurate, current, and
          complete information. Each Sign-In Name and corresponding Password can
          be used by only one User, and sharing your access credentials with
          others (especially if they are using Your credentials to access Our
          Content (as defined below) is a material breach of these Terms of
          Service. You are solely responsible for the confidentiality and use of
          your Sign-In Name and Password, as well as for any use, misuse, or
          communications entered through the Websites using one or more of them.
          You will promptly inform Us of any need to deactivate a Password or
          Sign-In Name. Brandkart will not be liable for any loss or damage
          caused by any unauthorised use of Your Account. By creating the User
          Account and providing details of a business entity, You confirm that
          You are an authorised employee, contractor, or representative of the
          business entity. Brandkart shall not be responsible in any manner and
          bear no liability for Your activities carried out without such
          authorisation.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Use of the website
        </h2>

        <p className="mt-2 text-sm md:text-base">
          By accessing and using the Website, You acknowledge that You are
          responsible for your actions. You represent and warrant, to the best
          of your knowledge that:
          <ul className="mt-2 list-disc ml-6">
            <li>
              You have all the necessary rights, power and authority to enter
              into these Terms of Service and to fulfil your contractual
              obligations hereunder;
            </li>
            <li>
              Any and all information You post or provide is true, accurate and
              not misleading and that You will not allow any other person or
              entity to use your User Account;
            </li>
            <li>
              The information and User Content You provide, upload, post,
              e-mail, transmit, or otherwise make available to Us, or on the
              Website, including without limitation reviews, trademarks, logos,
              screenshots and videos is accurate and free of third-party
              encumbrances;
            </li>
            <li>
              You will, when approving or providing User Content on the Website,
              adhere to all the applicable guidelines and bear all liability
              related to your submission of such Content;
            </li>
            <li>
              You will not post or otherwise provide User Content that is
              unlawful, defamatory, infringing, libelous, abusive, disparaging,
              pornographic, invasive of another's privacy, promotes illegal
              activities/conduct or violates applicable laws or regulations;
            </li>
            <li>
              You will not post or otherwise provide User Content that You do
              not have the right to make available under any law or contractual
              or fiduciary relationship (such as insider information,
              proprietary and confidential information, learned or disclosed as
              part of employment relationships, or information protected under
              nondisclosure agreements);
            </li>
            <li>
              You will not post or otherwise provide User Content that contains
              a software virus or any other code files or programs with the
              ability to interrupt, destroy, compromise, or otherwise limit the
              functionality of any computer software or hardware or
              telecommunications equipment;
            </li>
          </ul>
          <p className="mt-2">
            At Our discretion, We may, but are not obligated to, monitor and
            terminate User activity on the Website, and/or edit or remove User
            Content, which violates or otherwise fails to comply with these
            Terms of Service.
          </p>
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Fees</h2>

        <p className="mt-2 text-sm md:text-base">
          You shall pay Brandkart all applicable fees and taxes (collectively,
          the “Fees”) for the Service rendered by Brandkart. We (or our
          third-party payment processor) shall authorise your credit card, bank
          account, or other approved payment facility that You provided during
          the registration process for the full payment of the Fees, and you
          hereby consent to the same. All Fees will be charged and made in
          Indian Rupee.
        </p>

        <p className="mt-2 text-sm md:text-base">
          You are responsible for ensuring that all payment information is
          correct and the funds necessary for payment are available. You agree
          not to hold Us liable for payments that do not reach Us because You
          have quoted incorrect payment information or the Payment Processors
          refused the payment for any other reason.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Confidentiality
        </h2>

        <p className="mt-2 text-sm md:text-base">
          “Confidential Information” means all non-public information, in any
          form and on any medium, whether or not designated by a party (the
          “Disclosing Party”) as confidential and disclosed by such party to the
          other party (the “Receiving Party”) under this Contract, regardless of
          the form of disclosure, and includes without limitation and without
          the need to designate as confidential and related documents and
          information; BUT DOES NOT INCLUDE any information to the extent, but
          only to the extent, that such information becomes or is: (a) already
          known to or in the possession of the Receiving Party free of any
          restriction at the time it is obtained from the Disclosing Party; (b)
          subsequently learned by the Receiving Party from an independent third
          party free of any restriction and without breach of this Contract or
          any Contract with such third party; (c) publicly available through no
          wrongful act of the Receiving Party; or (d) independently developed by
          the Receiving Party without reference to any Confidential Information,
          as evidenced by the ordinary business records of the Receiving Party.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Intellectual property rights
        </h2>
        <p className="mt-2 text-sm md:text-base">
          Other than content You own, which You may have opted to include on
          this Website, under these Terms of Service, Brandkart owns all rights
          to the intellectual property and material contained in this Website,
          and all such rights are reserved.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Your content</h2>

        <p className="mt-2 text-sm md:text-base">
          By submitting Your Content, You guarantee that you have the legitimate
          rights to use the Content you have provided. You must not infringe any
          intellectual property rights of others through your Content. You grant
          Brandkart unrestricted, sub-licensable, royalty-free, perpetual, and
          irrevocable rights to store, use, distribute, advertise, adapt, remix,
          modify, display, perform, excerpt, prepare derivative works of,
          reproduce, and sell Your Content for the purposes of providing You
          with the requested services and carrying out Brandkart’s legitimate
          business interests. You understand and agree that Brandkart has no
          obligation to monitor or review Your Content. Brandkart reserves the
          right, at its sole discretion, to refuse to upload, modify, delete, or
          remove Your Content, in whole or in part, that violates these Terms of
          Service or may harm the reputation of Brandkart.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium uppercase">
          #Disclimer
        </h2>
        <p className="mt-2 text-sm md:text-base">
          EXCEPT AS SPECIFICALLY SET FORTH HEREIN (I) YOUR USE OF THE WEBSITE IS
          AT YOUR OWN RISK, AND (II) TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, THE WEBSITE IS PROVIDED ON AN "AS IS" AND "AS
          AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION: ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF
          THIRD PARTY INTELLECTUAL PROPERTY RIGHTS, TITLE, CUSTOM, TRADE, QUIET
          ENJOYMENT, ACCURACY OF INFORMATIONAL CONTENT OR SYSTEM INTEGRATION.
          WHILE THE WEBSITE MAY SERVE AS A PLATFORM TO HOST; INCLUDING BUT NOT
          LIMITED TO; SOFTWARE/PLATFORMS/AGENCIES/TOOLS AND SERVICE AND PRODUCT
          LISTINGS, WE ARE NOT RESPONSIBLE FOR ENSURING THE LEGAL AND/OR
          REGULATORY COMPLIANCE OF ANY SERVICE MADE AVAILABLE ON THE WEBSITE. WE
          DO NOT WARRANT THAT THE WEBSITE WILL BE SECURE, AVAILABLE OR OPERATE
          IN AN UNINTERRUPTED OR ERROR-FREE MANNER; THAT ERRORS OR DEFECTS WILL
          BE CORRECTED; OR THAT THE CONTENT ON OUR SITES IS ACCURATE OR
          APPROPRIATE FOR YOU AND YOUR BUSINESS NEEDS.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Indemnification
        </h2>
        <p className="mt-2 text-sm md:text-base">
          The User (in its capacity as “Indemnitor”) agrees to indemnify, defend
          and hold harmless Brandkart, its agents, affiliates and employees (in
          its capacity as "Indemnitee”) from and against any and all third-party
          claims, liabilities, losses and expenses (including damage awards,
          settlement amounts and reasonable attorneys’ fees) arising out of or
          relating to User’s respective violation of these Terms of Service,
          except to the extent such losses and expenses arise from the
          negligence or wilful misconduct of Indemnitee.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Limitation of Liability
        </h2>
        <p className="mt-2 text-sm md:text-base">
          We will not be liable for any consequential, special, indirect,
          exemplary or punitive damages arising out of or in any way related to
          use of service, Users’ use of the Website, including without
          limitation, loss of profits, revenue, interest, goodwill, loss or
          corruption of data or other interruption of business (whether in
          contract, tort or under other legal theory), even if advised of such
          damages.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Termination</h2>
        <p className="mt-2 text-sm md:text-base">
          We reserve the right, in our sole discretion, without penalty and at
          any time without notice, to modify or discontinue (temporarily or
          permanently) the service, or any part thereof if we believe that User
          is violating these Terms of Service. If the User uses or attempts to
          use the Website for any purpose that contravenes these Term of Service
          (including without limitation tampering, hacking, data scraping,
          modifying or otherwise corrupting the security or functionality of the
          Website), the User may also be subject to civil and criminal
          liability.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Severability</h2>
        <p className="mt-2 text-sm md:text-base">
          If any provision of these Term of Service is found to be unenforceable
          or invalid under any applicable law, such unenforceability or
          invalidity shall not render these Term of Service unenforceable or
          invalid as a whole, and such provisions shall be deleted without
          affecting the remaining provisions herein.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Assignment</h2>
        <p className="mt-2 text-sm md:text-base">
          Brandkart shall be permitted to assign, transfer, and subcontract its
          rights and/or obligations under these Terms of Service without any
          notification or consent required. However, You shall not be permitted
          to assign, transfer, or subcontract any of your rights and/or
          obligations under these Terms of Service.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Force Majure</h2>
        <p className="mt-2 text-sm md:text-base">
          Brandkart will not be liable for any failure or delay of performance
          under these Terms of Service resulting from a force majeure event
          beyond its reasonable control, including, without limitation, natural
          disasters, acts of God, government regulations, war, terrorism, labor
          disputes, power failures, epidemic and pandemic.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Governing Law</h2>
        <p className="mt-2 text-sm md:text-base">
          These Terms of Service, including non-contractual rights and
          obligations arising out of or in connection with these Terms of
          Service, shall be governed by the laws of India. Any legal proceedings
          arising out of these Terms of Service or relating thereto, shall be
          instituted in Courts of Bengaluru, Karnataka only.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Waiver & Severability
        </h2>
        <p className="mt-2 text-sm md:text-base">
          Our failure to act with respect to a breach of these Terms of Service
          will not constitute a waiver and does not waive our right to act with
          respect to subsequent or similar breaches. If any of these Terms of
          Service shall be deemed invalid, void, or for any reason unenforceable
          under applicable law, then that term shall be deemed severable and
          shall not affect the validity and enforceability of any remaining term
          or provision of these Terms of Service.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Entire Agreement
        </h2>
        <p className="mt-2 text-sm md:text-base">
          These Terms of Service along with the documents referred to herein,
          constitute the entire and exclusive agreement between Brandkart and
          the User with regard to User’s use of the service through this
          Website.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #International Users
        </h2>
        <p className="mt-2 text-sm md:text-base">
          If you access the Site from a location outside India, you do so at
          your own risk and are responsible for compliance with all applicable
          laws, rules, regulations or decrees of your jurisdiction.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">
          #Entire Contract
        </h2>
        <p className="mt-2 text-sm md:text-base">
          These Terms of Service constitute the entire and exclusive
          understanding and agreement between Brandkart and you regarding the
          Service and supersede and replace any and all prior oral or written
          understandings or agreements between Brandkart and you regarding the
          same.
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Contact</h2>
        <p className="mt-2 text-sm md:text-base">
          You may contact BRANDKART via:
        </p>
        <p className="mt-2 text-sm md:text-base">Telephone : +91- 9558415021</p>
        <p className="mt-2 text-sm md:text-base">
          E-mail : contact@brandkart.co
        </p>
        <p className="mt-2 text-sm md:text-base">
          Web : https://www.brandkart.co/#/contact-us
        </p>

        <h2 className="mt-4 text-xl md:text-2xl font-medium">#Revision</h2>
        <p className="mt-2 text-sm md:text-base">
          We often update the Website and Services offered on this Platform. As
          a result, we may change/modify/ alter/ update/ add or discontinue any
          terms and conditions laid down herein. You can determine the
          applicable terms by referring to the "LAST UPDATED" legend below. It
          shall be your responsibility to check terms periodically for changes.
          Please check this page regularly to ensure you are familiar with the
          updated Terms & Conditions. By using Brandkart in any manner
          whatsoever and in any capacity whatsoever, it shall be deemed that you
          have seen the updated terms and conditions and consented thereto.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
